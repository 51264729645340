import React, { useRef, useState } from "react"
import { Row, Col, Container } from "reactstrap"
import styled from "styled-components"
import PlayButton from "../../images/play-button.png"

type Props = {
  poster?: string
  video: string
  photo?: string
  anotherPhoto?: string
}

const CustomContainer = styled(Container)`
  @media (min-width: ${(props) => props.theme.sizes_min.xxl}) {
    max-width: 50%;
  }
`

const PlayImg = styled.img`
  display: none;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`

const Img = styled.img`
  max-width: 100%;
  height: auto;

  @media (min-width: ${(props) => props.theme.sizes_min.md}) {
    max-height: 450px;
    width: auto;
  }
`

const DownloadedVideo: React.FC<Props> = ({
  poster,
  video,
  photo,
  anotherPhoto,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  return (
    <CustomContainer fluid>
      <Row className="justify-content-center">
        <Col md={6} className="d-flex mb-4">
          <video
            ref={videoRef}
            width="480"
            height="854"
            controls
            poster={poster}
            className="m-auto img-fluid"
          >
            <source src={video} type="video/mp4" className="" />
            Váš prohlížeč nedokáže přehrát video tag.
          </video>
          {!isPlaying && (
            <PlayImg
              src={PlayButton}
              alt=""
              width={100}
              height={100}
              id="play-button"
              className="d-none d-md-block"
              onClick={playVideo}
            />
          )}
        </Col>
        {photo && anotherPhoto && (
          <Col md={6} className="m-auto">
            <Img
              src={photo}
              alt=""
              width="800"
              height="450"
              loading="lazy"
              className="mb-4 ml-auto"
            />
            <Img
              src={anotherPhoto}
              alt=""
              width="800"
              height="450"
              loading="lazy"
              className="ml-auto"
            />
          </Col>
        )}
      </Row>
    </CustomContainer>
  )
}

export default DownloadedVideo
