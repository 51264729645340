import PenguisTable from "../images/reference/penguins_table.svg"
import PneguinBubbles from "../images/reference/penguin_with_bubbles.svg"
import PenguinOneBubble from "../images/reference/penguin_bubble.svg"
import PenguinDeal from "../images/reference/penguin_deal.svg"

export const CARLINI_TIMELINE = [
  {
    year: "říjen 2022",
    header: "workshop ke strategii a značce Carlini, výstupy",
    image: PenguisTable,
  },
  {
    year: "listopad 2022",
    header: "audit sociálních sítí (Facebook a Instagram)",
    image: PneguinBubbles,
  },
  {
    year: "prosinec 2023",
    listItems: ["obsahový plán", "správa kampaní na sociálních sítích"],
    image: PenguinOneBubble,
  },
  {
    year: "leden až únor 2023",
    header: "konzultace",
    image: PenguinDeal,
  },
]
