import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import OgImage from "../../../images/reference/carlini/og-image.png"
import Brand from "../../../images/reference/carlini/carlini-brand.png"
import Poster from "../../../images/reference/carlini/poster.jpg"
import Uvod from "../../../images/reference/carlini/carlini-uvod.jpg"

import Michaela from "../../../images/reference/carlini/michaela.jpg"
import Post1 from "../../../images/reference/carlini/post1.png"
import Post2 from "../../../images/reference/carlini/post2.png"
import Post3 from "../../../images/reference/carlini/post3.png"
import Post4 from "../../../images/reference/carlini/post4.png"
import Predstaveni2 from "../../../images/reference/carlini/predstaveni-2.jpg"
import Predstaveni3 from "../../../images/reference/carlini/predstaveni-3.jpg"
import Video from "../../../videos/video.mp4"
import Logo from "../../../images/reference/carlini/logo.jpg"
import {
  ContainerCustom,
  Content,
  H2,
  Medailon,
  PaddingWrapper,
  Timeline,
} from "@igloonet-web/shared-ui"
import { Col, Container, Row } from "reactstrap"
import Kristy from "../../../images/team/mini/kristy.jpg"
import { CARLINI_TIMELINE } from "../../../model/carlini-reference-timeline"
import DownloadedVideo from "../../../components/reference-detail/downloaded-video"

type Props = {
  src: string
  width: string
  height: string
  src2: string
  width2: string
  height2: string
}

export const ImgWrapper: React.FC<Props> = ({
  src,
  width,
  height,
  src2,
  width2,
  height2,
}) => {
  return (
    <div className="m-auto text-center">
      <Row className="justify-content-around">
        <Col lg={6}>
          <img
            src={src}
            alt=""
            width={width}
            height={height}
            className="img-fluid"
            loading="lazy"
          />
        </Col>
        <Col lg={6} className="m-auto">
          <img
            src={src2}
            alt=""
            width={width2}
            height={height2}
            className="img-fluid"
            loading="lazy"
          />
        </Col>
      </Row>
      <p className="pt-2 mx-auto">
        <em>
          Ukázka příspěvků na sociálních sítích (Zdroj: FB stránka Carlini)
        </em>
      </p>
    </div>
  )
}

const Carlini: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 900px)")

    const handleMediaQueryChange = (event) => {
      setIsDesktop(event.matches)
    }

    setIsDesktop(mediaQuery.matches)

    mediaQuery.addListener(handleMediaQueryChange)

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange)
    }
  }, [])

  const ContentOfBlock = (
    <>
      <H2>Jak jsme postupovali</H2>
      <Content>
        <ol>
          <li>
            <strong>Workshop ke strategii a&nbsp;značce</strong>
            <p>
              Nejprve jsme na celodenním workshopu zjistili veškeré potřeby
              a&nbsp;cíle klienta a&nbsp;nahlédli pod pokličku jeho podnikání.
              Setkání se zúčastnili oba majitelé, provozní a&nbsp;pekařka, díky
              čemuž jsme získali cenné informace napříč celým podnikem. Na nich
              jsme pak stavěli v&nbsp;navazujících fázích spolupráce. Celý
              workshop byl provázán hrami a&nbsp;debatami.
            </p>
          </li>
          <li>
            <strong>Výstupy z workshopu</strong>
            <p>
              Všechny informace z&nbsp;workshopu jsme rovnou zapisovali do
              mindmapy. Hlavní a&nbsp;nosné myšlenky jsme shrnuli do přehledné
              jednostránkové Brand Canvas.
            </p>
          </li>

          <PaddingWrapper>
            <Medailon
              noMargin
              fullWidth
              noCover
              noPenguin
              personName="Kristýna Edingerová"
              position={`marketingová specialistka,<br/>vedoucí projektu`}
              img={Kristy}
            >
              <p>
                Brand Canvas přináší rychlou orientaci a&nbsp;jasný směr
                –&nbsp;jakou máme vizi, čeho chceme v&nbsp;blízkých letech
                dosáhnout, kdo je naše cílovka, co jí nabízíme, jak k&nbsp;ní
                mluvíme a&nbsp;jaké symboly značky jí chceme vštípit? Strategie
                na jedné stránce a&nbsp;vždy po ruce.
              </p>
            </Medailon>
          </PaddingWrapper>

          <Container className="mx-auto mb-5 text-center">
            <img
              src={Brand}
              alt=""
              className="img-fluid w-md-50"
              loading="lazy"
              width={652}
              height={636}
            />
            <p className="text-center pt-3">
              <em>Ukázka sekce Brand komunikace z Brand Canvas</em>
            </p>
          </Container>
          <li>
            <strong>Audit sociálních sítí</strong>
            <p>
              Audit sociálních sítí přinesl zhodnocení aktuálního stavu
              komunikace –&nbsp;na jakých platformách Carlini působí; jaké typy
              příspěvků přidává na sociální sítě; témata; frekvence, práce s
              brandem atd. Na základě poznatků jsme vytvořili doporučení, jak
              k&nbsp;příspěvkům na sítích přistupovat.
            </p>
          </li>

          <ImgWrapper
            src={Post3}
            width="525"
            height="650"
            src2={Post4}
            width2="515"
            height2="386"
          />

          <li>
            <strong>Obsahový plán</strong>
            <p>
              Na workshop a&nbsp;audit navázal obsahový plán. Vytvořili jsme
              komplexní tabulku na tvorbu a&nbsp;plánování příspěvků na
              sociálních sítích. Na osobní schůzce jsme paní Carlini proškolili,
              aby zvládla sestavit obsahový plán na daný měsíc a&nbsp;průběžně
              s&nbsp;ním pracovat.
            </p>
          </li>
          <li>
            <strong>Struktura propagace a&nbsp;správa kampaní</strong>
            <p>
              Naučili jsme klientku pracovat s&nbsp;Business Managerem, aby si
              sama mohla spravovat kampaně na propagaci příspěvků. Předtím jsme
              vytvořili jednoduchou strukturu kampaní, aby se v&nbsp;ní vyznala
              a&nbsp;správa placené propagace pro ni nebyla náročná.
            </p>
          </li>
          <li>
            <strong>Konzultace</strong>
            <p>
              Jedna věc je plán komunikace a&nbsp;propagace správně připravit,
              druhá pak uvést jej v&nbsp;život. Nejde o&nbsp;jednoduchou
              disciplínu, proto jsme byli v&nbsp;následujících měsících paní
              Carlini neustále k ruce –&nbsp;dokud si nebyla jistá, že dělá vše
              správně. Pomáhali jsme jak s&nbsp;obsahovým plánem
              a&nbsp;reklamou, tak s&nbsp;texty, grafikou
              a&nbsp;s&nbsp;čímkoliv, co bylo potřeba.
            </p>
          </li>

          <ImgWrapper
            src={Post1}
            width="504"
            height="623"
            src2={Post2}
            width2="504"
            height2="623"
          />
        </ol>
      </Content>
    </>
  )

  return (
    <Layout>
      <Helmet>
        <title>
          Upekli jsme Carlini komunikaci a propagaci na sítích | igloonet.cz
        </title>
        <meta
          name="description"
          content="Tým igloonetu nám poskytl úplně nový pohled do světa marketingu. Pro celou naši firmu to byla skvělá zkušenost a určitě to nebyla spolupráce jednorázová!“ Michaela Carlini, jednatelka"
        />
        <meta
          property="og:title"
          content="Upekli jsme Carlini komunikaci a propagaci na sítích | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <ReferenceHeader
        companyName="Carlini"
        heading="Upekli jsme Carlini komunikaci a&nbsp;propagaci na sociálních sítích"
        date="říjen 2022 – únor 2023"
      />

      <ReferenceImageText
        right
        img={Uvod}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo společnosti Carlini"
        link="//carlini.cz/"
      >
        <p>
          Brněnsko-italské řemeslné srdcovce Carlini se daří. Plánuje další
          rozšíření a&nbsp;růst. Chce o&nbsp;sobě dát vědět širším publikům.
          Dali jsme proto společně dohromady základní strategii, vytvořili
          obsahový plán, navrhli propagační strukturu a&nbsp;klienta naučili,
          jak s tím vším pracovat. Mrkněte se, jak to hezky odsýpalo ↓
        </p>
      </ReferenceImageText>

      <PaddingWrapper>
        <Container fluid className="text-center pt-5 mt-2">
          <Timeline data={CARLINI_TIMELINE}></Timeline>
        </Container>
      </PaddingWrapper>

      <ReferenceImageText>
        <H2>Carlini</H2>
        <p>
          Italskou řemeslnou pekárnu Carlini nelze minout. Stačí jít v&nbsp;Brně
          na Smetanově ulici po čuchu!
        </p>
        <p>
          Najdete v&nbsp;ní skvělou partu v&nbsp;čele se zakladateli Michaelou a
          Antoniem Carlini a neméně výbornou focacciu, typické loupakoule,
          grissini a&nbsp;samozřejmě chleba z&nbsp;pravé italské mouky, kterou
          Carlini spolu s&nbsp;dalšími produkty dodávají i&nbsp;do dalších
          podniků.
        </p>
        <p>
          Mise je jasná: edukovat zákazníky o&nbsp;tom, jak jíst zdravě, šířit
          koncept glokálnosti (global + local) a&nbsp;propojit tak poctivé
          italské řemeslo s&nbsp;Českou republikou.
        </p>
      </ReferenceImageText>

      <DownloadedVideo
        poster={Poster}
        video={Video}
        photo={Predstaveni2}
        anotherPhoto={Predstaveni3}
      />

      <ReferenceImageText>
        <H2>Cíle spolupráce</H2>
        <Content noPadding>
          <p className="pl-3">Drželi jsme se dvou hlavních cílů:</p>
          <ol>
            <li>
              Nastavit dlouhodobou komunikaci Carlini na sociálních sítích.
            </li>
            <li>
              Naučit klienta spravovat si placenou propagaci příspěvků na
              Facebooku a Instagramu. Tak, aby zvládl sám, případně s malou
              pomocí, šířit povědomí o&nbsp;značce a&nbsp;produktech na širší
              publika.
            </li>
          </ol>
        </Content>
      </ReferenceImageText>

      {isDesktop ? (
        <ContainerCustom>{ContentOfBlock}</ContainerCustom>
      ) : (
        <>{ContentOfBlock}</>
      )}

      <ReferenceImageText>
        <H2>Navazující kroky</H2>
        <p>
          V&nbsp;následujících měsících si paní Carlini vyzkouší tvorbu
          příspěvků a&nbsp;jejich propagaci sama bez toho, aniž by nás měla jako
          support, na který se může kdykoliv a&nbsp;s&nbsp;čímkoliv obrátit. Po
          stanovené době provedeme audit a&nbsp;společně vyhodnotíme, jak se jí
          dařilo.
        </p>
      </ReferenceImageText>

      <Medailon
        personName="Michaela Carlini"
        position="jednatelka"
        img={Michaela}
        className="mt-5"
      >
        <p>
          Tým igloonetu nám poskytl úplně nový pohled do světa marketingu.
          Kristýna měla těžký úkol: naučit nás správně používat sociální sítě,
          plánovat si příspěvky a&nbsp;reklamní kampaně. Je to pro nás běh na
          dlouhou trať a&nbsp;mile mě překvapil její přístup, kdy mě nenechala
          usnout na vavřínech, připomínala se a&nbsp;kontrolovala celý můj
          postup. Všechny položené otázky mi byly vysvětlené jednoduše
          a&nbsp;srozumitelně. Pro celou naši firmu to byla skvělá zkušenost
          a&nbsp;určitě to nebyla spolupráce jednorázová!
        </p>
      </Medailon>
      <ContactForm contact="adam" />
    </Layout>
  )
}

export default Carlini
